import React, { useRef, useState } from "react";
import Section from "../../components/section";

import bg1 from "../../images/background-1.png";
import bgD1 from "../../images/background-deco-1.svg";

import app0 from "../../images/app/app-0.png";
import app1 from "../../images/app/app-1.png";
import app2 from "../../images/app/app-2.png";
import app3 from "../../images/app/app-3.png";
import app4 from "../../images/app/app-4.png";
import app5 from "../../images/app/app-5.png";
import app6 from "../../images/app/app-6.png";
import app7 from "../../images/app/app-7.png";

import { Navigation, Lazy, Pagination } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "swiper/css/bundle";

const images = [app0, app1, app2, app3, app4, app5, app6, app7];

// const Slide = ({ image }) => {
//   return (
//     <SwiperSlide>
//       <img data-src={image} className="swiper-lazy" />
//       <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
//     </SwiperSlide>
//   );
// };

const About = () => {
  const swiperRef = useRef();
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  return (
    <Section backgrounds={{ lg: [bg1, bgD1], sm: [bgD1] }} id="about">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <p className="text-white text-6xl mb-4 text-center">關於應用</p>
        <p className="text-white text-xl mb-4 text-center">
          為何要選擇此應用？
        </p>
        <div className="card max-w-xl">
          顧名思義是一個用來練習倉頡及速成輸入法的工具，
          憑著簡單設計的界面，豐富的功能及文字資料庫，讓大家可以隨時隨地拿起電話練習倉頡。
          倉頡也是一種為人熟識的輸入法，透過拆字，然後配對適當的字根來輸入一個字，大多都只有一個候選字，
          其選字的高準確度大大提升了學習它的價值。而速成簡化了倉頡輸入法，取其頭尾碼，
          但仍保留着其拆碼的精髓，較容易倉頡學習和掌握。
        </div>
        <div className="lg:hidden w-4/5 md:w-2/5 pt-8">
          <p
            className="text-white text-xl mb-4 text-center"
            onClick={() => swiperRef.current?.swiper.slideTo(6)}
          >
            應用截圖
          </p>
          <Swiper
            ref={swiperRef}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            lazy={true}
            pagination={{
              clickable: true,
            }}
            loop
            navigation={true}
            modules={[Lazy, Pagination, Navigation]}
            className="mySwiper"
          >
            {images.map((img, i) => (
              <SwiperSlide
                key={img}
                className="flex flex-col mb-8"
                onClick={() => {
                  setSelectedImageId(i + 1);
                  if (!showLightbox) setShowLightbox(true);
                }}
              >
                <img data-src={img} className="swiper-lazy" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {showLightbox && (
        <Lightbox
          mainSrc={images[selectedImageId]}
          nextSrc={images[(selectedImageId + 1) % images.length]}
          prevSrc={
            images[(selectedImageId + images.length - 1) % images.length]
          }
          onCloseRequest={() => setShowLightbox(false)}
          onMovePrevRequest={() =>
            setSelectedImageId(
              (old) => (old + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setSelectedImageId(
              (old) => (old + images.length + 1) % images.length
            )
          }
        />
      )}
    </Section>
  );
};

export default About;
